  .accordion-button-custom {
    color: white;
    background-color: #2e6540;
    background-image: linear-gradient(#2e6540, #000);
    border: none;
    padding: 12px 12px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    position: relative; /* Ensure positioning for absolute children */
    justify-content: space-between; /* Space out the contents */
  }
  
  .accordion-button-custom:not(.collapsed) {
    color: white;
    background-color: #2f2f2f;
    background-image: linear-gradient(#2f2f2f, #000);
    border: 1px solid #2f2f2f; /* Specify border width and color */
    border-radius: 10px;
  }

  .accordion-button-custom[aria-expanded='true'] {
    color: white;
    background-color: #2f2f2f;
    background-image: linear-gradient(#2f2f2f, #000);
    border-radius: 10px 10px 0 0;
  }
  
  .accordion-item-custom {
    border: none;
    margin-bottom: 30px; /* Add margin between accordion items */
  }
  
  .accordion-body-custom {
    color: azure;
    background-image: linear-gradient(135deg, rgba(18, 39, 25, .3), #2f2f2f);
    padding: 16px 24px;
    font-size: 16px;
    border: 1px solid #2f2f2f; /* Specify border width and color */
    border-radius: 0 0 10px 10px;
    overflow: hidden;
  }

  .accordion__panel--hidden {
    max-height: 0; /* Ensure the hidden panel has no height */
    padding: 0 24px; /* Reduce padding when collapsed */
  }

  .module-text {
    text-transform: uppercase;
    background-image: linear-gradient(135deg, #000, #021840);
    border: 0.5px solid #fff;
    border-radius: 12px 12px 12px 0;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    top: -22px;
    left: 0;
  }
  
  .module-text.pm.ai {
    background-image: linear-gradient(#000, #5a5a5a);
    border-color: #541095;
  }

  .arrow {
    display: inline-block;
    width: 10px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    padding: 5px;
    transform: rotate(45deg);
    transition: transform 0.3s ease; /* Transition for rotation */
  }
  
  .arrow.open {
    transform: rotate(225deg); /* Rotate when the accordion is open */
  }
  
  .arrow-container {
    display: flex;
    align-items: center;
    padding-left: 10px; /* Adjust padding as needed */
  }

  .accordion-body-custom h1,
  .accordion-body-custom h2,
  .accordion-body-custom h3,
  .accordion-body-custom h4,
  .accordion-body-custom h5,
  .accordion-body-custom h6 {
      color: #eee;
  }
  
  .accordion-body-custom p,
  .accordion-body-custom a,
  .accordion-body-custom span,
  .accordion-body-custom li,
  .accordion-body-custom ul,
  .accordion-body-custom ol,
  .accordion-body-custom div {
      color: #ddd;
  }
  
  .accordion-body-custom-light h1,
  .accordion-body-custom-light h2,
  .accordion-body-custom-light h3,
  .accordion-body-custom-light h4,
  .accordion-body-custom-light h5,
  .accordion-body-custom-light h6 {
      color: #222;
  }
  
  .accordion-body-custom-light p,
  .accordion-body-custom-light a,
  .accordion-body-custom-light span,
  .accordion-body-custom-light li,
  .accordion-body-custom-light ul,
  .accordion-body-custom-light ol,
  .accordion-body-custom-light div {
      color: #444;
  }