.banner-container {
    background-color: #00000090;
    backdrop-filter: blur(12px);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 16px;
    font-size: 16px;
    line-height: 1;
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 999;
  }
  
  @media screen and (max-width: 767px) {
    .banner-container {
      display: flex;
    }
  }

  @media screen and (min-width: 980px) {
    .banner-container {
        max-width: 980px;
        border-radius: 20px;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 20px;
        padding-left: 40px;
        padding-right: 40px;
    }
}
  
  .image {
    width: 100px;
    height: auto;
  }
  
  .register-button {
    color: #3b3b3b;
    /* background-color: #ffc845;
    border: 1px solid #ffc845; */
    border-radius: 60px;
    margin-top: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    transition: all 0.8s;
  }
  
  .flex-left {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    align-items: center;
    display: flex;
  }
  
  .price-container {
    padding: 16px;
    color: #fff;
  }
  
  @media screen and (max-width: 767px) {
    .price-container {
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 16px;
    }
  }
  
  .price-heading {
    color: #fff;
    font-size: 30px;
  }
  
  .original-price {
    color: rgba(255, 255, 255, 0.7);
    font-size: 20px;
    font-weight: 300;
    text-decoration: line-through;
  }
  
  .register-deadline {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }
  
  .seats-container {
    padding: 16px;
    position: relative;
  }
  
  .seats-info {
    z-index: 1;
    background-color: #202020;
    color: #fff;
    border-radius: 28px;
    padding: 5px 11px;
    font-size: 0.875rem;
    position: absolute;
    top: -15px;
    bottom: auto;
    left: auto;
    right: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  }
  
  .seats-left {
    font-weight: bold;
    color: #fff;
  }
  
  .register-button {
    color: #fff;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    background-image: linear-gradient(101deg, #8217ee, #e0257e);
    border-radius: 74px;
    justify-content: center;
    align-items: center;
    height: 60px;
    padding: 20px 80px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    text-decoration: none;
    display: flex;
    position: relative;
    overflow: hidden;
    box-shadow: 0 10px 20px #e0257e70;
    transition: transform 0.3s ease-in-out;
  }

.register-button:hover {
    text-decoration: none;
    transform: translate3d(0px, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}
  
  @media screen and (max-width: 520px) {
    .register-button {
      padding: 10px 20px;
      font-size: 14px;
      height: 50px;
    }
    
    .seats-info {
      top: -10px;
      right: 10px;
      font-size: 0.75rem;
    }
    
    .price-container {
      padding: 10px;
    }
    
    .price-heading {
      font-size: 20px;
    }
    
    .original-price {
      font-size: 14px;
    }
    
    .register-deadline {
      font-size: 14px;
    }
  }
