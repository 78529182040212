.first-two-cards {
    display: grid;
    grid-template-columns: 1fr 1fr; // Two columns
    grid-gap: 16px; // Gap between columns
    align-items: stretch; // Align items in a way that they stretch to fill the container

    @media screen and (max-width: 360px) {
        grid-template-columns: 1fr; // Stack them vertically on smaller screens
    }
}

.bigger-cards-container {
    display: flex;
    flex-direction: column;
    gap: 16px; /* Adjust the gap as needed */
  }

.custom-card {
    display: flex; // Using flex to control the internal layout
    flex-direction: column; // Stack children vertically
    justify-content: space-between; // Distribute space between items
    background-color: #1f1f1f;
    border-radius: 1rem;
    padding: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 100%; // Ensure card takes full height of its grid cell
}

.stats-card-tiles-light{
    background-color: #fff;
}

.text-dark-colors {
    color: #2a2a2a !important;
}

.text-light-colors {
    color: #ededed !important;
}

.banner-small {
    // background-color: #ebf1ff;
    padding-top: 30px;
    padding-bottom: 30px;
}

.banner-small-img {
    position: relative;
    padding: 30px 0;
    color: white;
  }
  
  .banner-small-img::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px; /* Set the desired width of the circle */
    height: 600px; /* Set the desired height of the circle */
    background-color: var(--banner-bg-color, #a58400);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    filter: blur(200px); /* Set the desired blur amount */
    z-index: 0;

    @media (max-width: 767px) {
        width: 100%; /* Set the desired width of the circle */
        height: 400px; /* Set the desired height of the circle */
        filter: blur(100px); /* Set the desired blur amount */
    }
  }

.banner-small-content-heading {
    margin-bottom: 1rem; // corresponds to Bootstrap's 'mb-4'
    font-size: 32px;
    font-weight: 800;

    @media (max-width: 767px) {
        text-align: center;
        font-size: 32px;
        font-weight: 800;
    }
}

.banner-small-content-grid-item-heading {
    color: #fff;
    text-align: center;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(96.62deg, #e0257e 25%, #8217ee);
    -webkit-background-clip: text;
    background-clip: text;
    //color: #8217ee;
    margin-top: 0;
    font-size: 24px;
    font-weight: 800;
    line-height: 140%;
}

.banner-small-content-grid-item-pr {
    color: #090909;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 140%;
    font-size: 18px;
    margin-top: 16px;
}

.container-xl {
    width: 100%;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 60px;
    padding-right: 60px;
}

.container-xl-low-padding {
    width: 100%;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
}

.container-max-width {
    max-width: 860px;
}

.banner-small-content-grid {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    display: flex;
    box-sizing: border-box;

    @media screen and (max-width: 991px) {
        flex-direction: column;
    }
}

.banner-small-content {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1.25fr 2fr;
    grid-auto-columns: 1fr;
    align-items: center;
    justify-items: center;
    padding-left: 60px;
    padding-right: 60px;
    display: grid;
    text-align: center;

    @media screen and (max-width: 991px) {
        flex-direction: column;
        display: flex;
    }
}


.thankyou-page-info h1,
.thankyou-page-info h2,
.thankyou-page-info h3,
.thankyou-page-info h4,
.thankyou-page-info h5,
.thankyou-page-info h6 {
    color: #fff;
}

.thankyou-page-info p,
.thankyou-page-info a,
.thankyou-page-info span,
.thankyou-page-info li,
.thankyou-page-info ul,
.thankyou-page-info ol,
.thankyou-page-info div {
    color: #c9c9c9;
}